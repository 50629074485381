import { useEffect, useState } from 'react';
import FilterUnitary from './FilterUnitary';
import { Table } from 'react-bootstrap';
import ToastWarning from '../../../components/ToastWarning';
import ButtonGrayUltimate from '../../../components/Buttons/ButtonGrayUltimate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import Pagination from '../../../components/Pagination';
import SpinnerDefault from '../../../components/Spinner';
import { SelectedSimulationData, SimulationData } from './interface';

const UnitarySimulations = () => {
    const [simulations, setSimulations] = useState<SimulationData[]>([]);
    const [filteredSimulations, setFilteredSimulations] = useState<SimulationData[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [toastMessage, setToastMessage] = useState('');
    const [selectedSimulation, setSelectedSimulation] = useState<SelectedSimulationData | null>(null);
    const token = localStorage.getItem('token');

    const itemsPerPage = 7;
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(filteredSimulations.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, filteredSimulations.length);
    const currentItems = filteredSimulations.slice(startIndex, endIndex);

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    const handleSimulations = async () => {
        try{
            setLoading(true);
            const findId = await fetch(`https://webservicesstaging.gfttech.com.br/api/v1/parceiro/dados-logado`,{
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if(!findId.ok){
                setToastMessage("Erro ao buscar usuário");
            }

            const partnerData = await findId.json();
            const partnerId = partnerData.partnerData.partner_id;

            const simulationData = await fetch(`https://acelereaistaging.gfttech.com.br/api/v1/proposal/simulation/find-by-user?userId=${partnerId}&userType=Partner`,{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const data: SimulationData[] = await simulationData.json();
            if(simulationData.ok){
                setSimulations(data);
                setFilteredSimulations(data);
            } else{
                if(data.length === 0){
                    setToastMessage("Nenhum registro encontrado");
                }
                setToastMessage("Erro ao buscar dados!");
            }

        } catch(error){
            setToastMessage("Erro ao buscar dados");
            console.log('Erro ao buscar dados:', error);
        } finally{
            setLoading(false);
        }
    }

    useEffect(() => {
        handleSimulations();
    }, [])

    const handleFilter = (cpf: string, startDate: string, endDate: string) => {
        let filtered = simulations;
    
        // Filtro pelo cpf 
        if (cpf) {
          filtered = filtered.filter(simulation => simulation.cpf.includes(cpf));
        }
    
        // Filtro pela data inicial
        if (startDate) {
          filtered = filtered.filter(simulation => new Date(simulation.date) >= new Date(startDate));
        }
    
        // Filtro pela data final
        if (endDate) {
          filtered = filtered.filter(simulation => new Date(simulation.date) <= new Date(endDate));
        }
    
        setFilteredSimulations(filtered);
        setCurrentPage(1);
      };

    const handleModal = (unitaryId: string) => {
        const simulation = simulations.find(simulation => simulation.id === unitaryId);
        if (simulation) {
            setSelectedSimulation(simulation); 
            setShowModal(true);
        }
    };

    return(
        <>
            <FilterUnitary onFilter={handleFilter} />

            <div className={`mt-5 mb-5`}>
                <div>
                    <Table className={`table table-responsive`}>
                        <thead>
                            <tr>
                                <th>ID DA CONSULTA</th>
                                <th>CPF</th>
                                <th>DATA E HORÁRIO DA CONSULTA</th>
                                <th>CONVÊNIO</th>
                                <th>AÇÕES</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ? (
                                <tr>
                                    <td colSpan={5}>
                                        <SpinnerDefault/>
                                    </td>
                                </tr>
                            ) : (
                                currentItems.map(simulation => (
                                    <tr key={simulation.id}>
                                        <td>{simulation.id}</td>
                                        <td>{simulation.cpf}</td>
                                        <td>{new Date (simulation.date).toLocaleString('pt-BR')}</td>
                                        <td>{simulation.covenantDescription}</td>
                                        <td>
                                            <ButtonGrayUltimate
                                                content={<FontAwesomeIcon icon={faInfoCircle} />}
                                                onClick={() => handleModal(simulation.id)}
                                            />
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </Table>
                </div>
                {totalPages > 1 && (
                    <Pagination
                        totalItems={filteredSimulations.length}
                        itemsPerPage={itemsPerPage}
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                    />
                )}
            </div>
                                        
           
        </>
    )
}

export default UnitarySimulations;