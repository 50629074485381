import React, { useState, useEffect } from 'react';
import { Col, Container, Form, Row, Card, Spinner, Table } from 'react-bootstrap';
import styles from './ClientData.module.scss';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NavBar from '../../../../components/NavBar';
import PageTitle from '../../../../components/PageTitle';
import PageSubTitle from '../../../../components/PageSubTitle';
import ButtonPrimary from '../../../../components/Buttons/ButtonPrimary';
import { IMaskInput } from 'react-imask';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import ToastWarning from '../../../../components/ToastWarning';
import { formatDate, isValidCPF, truncateText } from '../../../../Utils';

export default function ClientData() {
  const [menuOpen, setMenuOpen] = useState<boolean>(localStorage.getItem('isMenuOpen') === 'true');
  const [toastMessage, setToastMessage] = useState('');
  const [nameClient, setNameClient] = useState('');
  const [dateBirthday, setDateBirthday] = useState('');
  const [cpf, setCpf] = useState('');
  const [registration, setRegistration] = useState('');
  const [cpfLegalRepresentative, setCpfLegalRepresentative] = useState('');
  const [loading, setLoading] = useState(false);
  const [benefits, setBenefits] = useState<any[]>([]);
  const [selectedBenefit, setSelectedBenefit] = useState<number | null>(null);
  const [showAdditionalFields, setShowAdditionalFields] = useState(false);
  const [initialBenefit, setInitialBenefit] = useState<any>(null);
  const [requiresLegalRepCpf, setRequiresLegalRepCpf] = useState(false);
  const [margemOnline, setMargemOnline] = useState(true);
  const token = localStorage.getItem('token');

  const subtitleTextClient = 'Informe os dados do seu cliente.';

  const handleBack = () => {
    window.location.href = '/ConsultaUnitaria/Inss/SelecaoBancos';
  };

  const fetchBenefits = async (cpf: string) => {
    if (!isValidCPF(cpf)) {
      setToastMessage('CPF inválido.');
      return;
    }

    setLoading(true);
    setSelectedBenefit(null);
    setBenefits([]);
    setInitialBenefit(null);
    setRequiresLegalRepCpf(false);
    setCpfLegalRepresentative('');

    try {
      const response = await axios.get(
        `https://acelereaistaging.gfttech.com.br/api/v1/marketplace/benefits?cpf=${cpf}`
      );

      if (response.data.length > 0) {
        setBenefits(response.data);
        setInitialBenefit(response.data[0]?.beneficio || null);
        setNameClient(response.data[0]?.beneficio.nome);
        setDateBirthday(response.data[0]?.beneficio.dataNascimento);
        setShowAdditionalFields(false);
        setSelectedBenefit(0);
      } else {
        setBenefits([]);
        setInitialBenefit(null);
        setShowAdditionalFields(true);
      }
    } catch (error) {
      console.error('Erro ao buscar benefícios:', error);
      setShowAdditionalFields(true);
      setToastMessage('Nenhum benefício localizado!');
    } finally {
      setLoading(false);
    }
  };

  const handleSelectBenefit = (index: number, benefit: any) => {
    if (benefit.situacaoBeneficio === 'ATIVO') {
      if (selectedBenefit === index) {
        setSelectedBenefit(null);
      } else {
        setSelectedBenefit(index);
      }
    } else {
      setSelectedBenefit(null);
    }
  };

  const padWithZeros = (cpf: string): string => {
    const numericCPF = cpf.replace(/[^\d]+/g, '');
    return numericCPF.padStart(11, '0');
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setToastMessage('');

    if (!cpf) {
      setToastMessage('Por favor, preencha o CPF.');
      return;
    }

    if (!isValidCPF(cpf)) {
      setToastMessage('CPF inválido.');
      return;
    }

    if (registration.length < 8 && showAdditionalFields) {
      setToastMessage('A matrícula deve conter pelo menos 8 dígitos.');
      return;
    }

    if (requiresLegalRepCpf && !cpfLegalRepresentative) {
      setToastMessage('Por favor, preencha o CPF do representante legal.');
      return;
    }

    setLoading(true);

    try {
      const selectedBanks = JSON.parse(localStorage.getItem('selectedBankIds') || '[]');

      let numeroBeneficio: string;
      if (selectedBenefit !== null && benefits[selectedBenefit]) {
        numeroBeneficio = benefits[selectedBenefit].beneficio.beneficio;
      } else if (showAdditionalFields) {
        numeroBeneficio = registration;
      } else {
        setToastMessage('Selecione um benefício ou preencha a matrícula.');
        setLoading(false);
        return;
      }

      const requestBody: any = {
        cpf: cpf.replace(/[^\d]+/g, ''),
        numeroBeneficio: numeroBeneficio,
        idConvenio: '3',
        bancos: selectedBanks,
        margemOnline: margemOnline,
      };

      if (initialBenefit && initialBenefit.CpfRepresentanteLegal) {
        requestBody.cpfRepresentanteLegal = padWithZeros(initialBenefit.CpfRepresentanteLegal);
      }

      if (cpfLegalRepresentative) {
        requestBody.cpfRepresentanteLegal = padWithZeros(cpfLegalRepresentative.replace(/[^\d]+/g, ''));
      }

      const response = await axios.post(
        `https://acelereaistaging.gfttech.com.br/api/v2/engine/simulate`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        const nomeCliente = nameClient;
        const dataNascimento = dateBirthday;

        const dadosParaSalvar = {
          ...response.data,
          nomeCliente: nomeCliente,
          dataNascimento: dataNascimento,
        };
        localStorage.setItem('rs', JSON.stringify(dadosParaSalvar));
        window.location.href = `/ConsultaUnitaria/Inss/Resultado`;
      }
    } catch (error: any) {
      if (error.response && error.response.data) {
        const { error: errorCode, message } = error.response.data;
        if (errorCode === 'BAD_REQUEST_EXCEPTION' && message === 'A requisição está sem o número do cpf do representante legal') {
          setRequiresLegalRepCpf(true);
          setToastMessage('Por favor, informe o CPF do representante legal.');
        } else {
          setToastMessage(`Erro: ${message}`);
        }
      } else {
        setToastMessage('Erro inesperado. Por favor, tente novamente mais tarde.');
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (benefits.length > 0 && !initialBenefit) {
      setInitialBenefit(benefits[0]?.beneficio || null);
    }
  }, [benefits, initialBenefit]);

  const isProsseguirDisabled = () => {
    if (showAdditionalFields && !registration) return true;
    if (requiresLegalRepCpf && !cpfLegalRepresentative) return true;
    if (selectedBenefit === null) return showAdditionalFields ? !registration : true;

    const selectedBenefitData = benefits[selectedBenefit];
    if (selectedBenefitData) {
      return selectedBenefitData.beneficio.situacaoBeneficio !== 'ATIVO';
    }

    return true;
  };

  return (
    <>
      <section className={styles.navbarSection}>
        <NavBar onToggleMenu={(isMenuOpen: boolean) => setMenuOpen(isMenuOpen)} />
      </section>

      <motion.div
        initial={{ x: 1000, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: 1000, opacity: 0 }}
        transition={{ duration: 1.2 }}
      >
        <div className={`${styles.titlesSection}`}>
          <div className={`${styles.titleSection} mt-5`}>
            <PageTitle size="h2">Consulta Unitária INSS</PageTitle>
          </div>
          <div className={`${styles.subTitleSection} mb-5`}>
            <PageSubTitle size="h5">{subtitleTextClient}</PageSubTitle>
          </div>
        </div>
        <Container className={`${styles.contentSection} ${menuOpen ? styles.sideBarOpen : styles.sideBarClose}`}>
          <Row>
            <Col sm={12} md={4}>
              <Form.Group className={styles.floatingLabel} controlId="cpf">
                <IMaskInput
                  mask="000.000.000-00"
                  className={`${styles.input} form-control`}
                  placeholder="CPF*"
                  value={cpf}
                  onAccept={(value: string) => {
                    setCpf(value);
                    fetchBenefits(value.replace(/[^\d]+/g, ''));
                  }}
                />
                <Form.Label>CPF*</Form.Label>
                {loading && <Spinner animation="border" size="sm" className="ml-2" />}
              </Form.Group>
            </Col>
            {(showAdditionalFields || requiresLegalRepCpf) && (
              <>
                {showAdditionalFields && (
                  <Col sm={12} md={4}>
                    <Form.Group className={styles.floatingLabel} controlId="registration">
                      <Form.Control
                        type="text"
                        placeholder="Matrícula"
                        value={registration}
                        onChange={(e) => setRegistration(e.target.value)}
                        required
                      />
                      <Form.Label>Matrícula*</Form.Label>
                    </Form.Group>
                  </Col>
                )}
                <Col sm={12} md={4}>
                  <Form.Group className={styles.floatingLabel} controlId="cpfLegalRepresentative">
                    <IMaskInput
                      mask="000.000.000-00"
                      className={`${styles.input} form-control`}
                      placeholder="CPF Representante Legal*"
                      value={cpfLegalRepresentative}
                      onAccept={(value: string) => setCpfLegalRepresentative(value)}
                      required={requiresLegalRepCpf}
                    />
                    <Form.Label>CPF Representante Legal{requiresLegalRepCpf ? '*' : ''}</Form.Label>
                  </Form.Group>
                </Col>
              </>
            )}
          </Row>

          {benefits.length > 0 && (
            <Row className="mt-4">
              <Col>
                <h5>Selecione um benefício:</h5>
                <div className={styles.benefitsGrid}>
                  {benefits
                    .reduce((acc: any[], benefit) => {
                      const beneficioNome = benefit.beneficio.beneficio;
                      if (!acc.some((b) => b.beneficio.beneficio === beneficioNome)) {
                        acc.push(benefit);
                      }
                      return acc;
                    }, [])
                    .map((uniqueBenefit, index) => (
                      <div key={uniqueBenefit.beneficio.beneficio}>
                        <Card
                          className={`${styles.benefitCard} ${
                            selectedBenefit === index ? styles.selected : ''
                          } ${
                            uniqueBenefit.beneficio.situacaoBeneficio !== 'ATIVO' ? styles.disabled : ''
                          }`}
                          onClick={() => handleSelectBenefit(index, uniqueBenefit.beneficio)}
                        >
                          <Card.Body>
                            <Card.Title>{uniqueBenefit.beneficio.beneficio}</Card.Title>
                            <Card.Text>
                              <strong>Espécie:</strong> {uniqueBenefit.beneficio.especie.codigo} -{' '}
                              {truncateText(uniqueBenefit.beneficio.especie.descricao, 23)}
                              <br />
                              <strong>Situação:</strong> {uniqueBenefit.beneficio.situacaoBeneficio}
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </div>
                    ))}
                </div>
              </Col>
            </Row>
          )}

          {initialBenefit && (
            <div className={styles.footerContainer}>
              <h5>Dados do Cliente</h5>
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>Nome</th>
                    <th>Data de Nascimento</th>
                    <th>Cidade</th>
                    <th>UF</th>
                    {initialBenefit?.CpfRepresentanteLegal && (
                      <>
                        <th>CPF Representante</th>
                        <th>Nome Representante</th>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{initialBenefit.nome}</td>
                    <td>{formatDate(initialBenefit.dataNascimento)}</td>
                    {initialBenefit.enderecoPessoal && (
                      <>
                        <td>{initialBenefit.enderecoPessoal.cidade}</td>
                        <td>{initialBenefit.enderecoPessoal.uf}</td>
                      </>
                    )}
                    {initialBenefit?.CpfRepresentanteLegal && (
                      <>
                        <td>{initialBenefit.CpfRepresentanteLegal}</td>
                        <td>{initialBenefit.NomeRepresentanteLegal}</td>
                      </>
                    )}
                  </tr>
                </tbody>
              </Table>
              <div className='d-flex justify-content-end'>
                <Form.Group className="mb-3" controlId="margemOnline">
                  <Form.Check
                    type="switch"
                    label="Consulta Turbo"
                    checked={margemOnline}
                    onChange={(e) => setMargemOnline(e.target.checked)}
                  />
                </Form.Group>
              </div>
            </div>
          )}

          <Row className="mt-5">
            <Col className="d-flex justify-content-start mt-5">
              <ButtonPrimary
                content={
                  <>
                    <FontAwesomeIcon icon={faArrowLeft} /> <span>Voltar</span>
                  </>
                }
                onClick={handleBack}
              />
            </Col>
            <Col className="d-flex justify-content-end mt-5">
              <ButtonPrimary
                content={
                  <>
                    <span>Prosseguir</span>
                    {loading && <Spinner animation="border" size="sm" className="ml-2" />}
                    <FontAwesomeIcon icon={faArrowRight} />
                  </>
                }
                onClick={handleSubmit}
                disabled={isProsseguirDisabled()}
              />
            </Col>
          </Row>
        </Container>
        {toastMessage && <ToastWarning message={toastMessage} />}
      </motion.div>
    </>
  );
}