import React, { useEffect, useState } from "react";
import {
  Modal,
  Table,
  Alert,
  Accordion,
  Tabs,
  Tab,
  Badge,
} from "react-bootstrap";
import ButtonPrimary from "../../../Buttons/ButtonPrimary";
import Pagination from "../../../Pagination";
import styles from "./ViewDataBatchSimulation.module.scss";
import PageSubTitle from "../../../PageSubTitle";
import { BankData, Simulation, PagedResponse } from "./interface";
import SpinnerDefault from "../../../Spinner";
import { formatCPF, formatCurrency, renderBankName } from "../../../../Utils";
import axios from "axios";

interface ViewDataBatchSimulationProps {
  show: boolean;
  batchId: string;
  onHide: () => void;
}

const ViewDataBatchSimulation: React.FC<ViewDataBatchSimulationProps> = ({
  show,
  batchId,
  onHide,
}) => {
  const [simulations, setSimulations] = useState<Simulation[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [toastMessage, setToastMessage] = useState("");
  const [selectedBankTyping, setSelectedBankTyping] = useState<string | null>(null);
  const [numberContract, setNumberContract] = useState<string | null>(null);
  const [birthDate, setBirthDate] = useState("");

  // Estados para o SimpleDigitationModal
  const [showDigitationModal, setShowDigitationModal] = useState(false);
  const [selectedBankData, setSelectedBankData] = useState<BankData | null>(null);
  const [selectedSimulationForDigitation, setSelectedSimulationForDigitation] = useState<Simulation | null>(null);

  // Estados para o PortTypingModal
  const [showPortTypingModal, setShowPortTypingModal] = useState(false);
  const [selectedConditionId, setSelectedConditionId] = useState<string | null>(null);
  const [selectedSimulationForPort, setSelectedSimulationForPort] = useState<Simulation | null>(null);

  // Estados para a paginação
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage] = useState<number>(5);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(1);

  useEffect(() => {
    if (show) {
      setLoading(true);
      setError(null);
      fetchSimulations();
      setToastMessage("");
    }
  }, [show, batchId, currentPage]);

  const fetchSimulations = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Token não encontrado");
      }

      const response = await fetch(
        `https://acelereaistaging.gfttech.com.br/api/v2/engine/simulate/paged?idLoteSimulacao=${batchId}&page=${currentPage}&limit=${itemsPerPage}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const contentType = response.headers.get("content-type");

      if (!response.ok) {
        throw new Error("Aguarde o processamento do lote.");
      }

      if (contentType && contentType.includes("application/json")) {
        const data: PagedResponse = await response.json();
        setSimulations(data.data);
        setTotalItems(data.totalItems);
        setTotalPages(data.totalPage);
      } else {
        throw new Error("A resposta da API não está no formato JSON esperado");
      }
    } catch (err: any) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleExport = (idLote: string) => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Token não encontrado");
      return;
    }
    fetch(`https://acelereai.gfttech.com.br/api/v2/engine/simulate/bulk-download/${idLote}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Erro ao exportar: ${response.statusText}`);
        }
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = `lote_${idLote}.xlsx`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
        console.log("Download iniciado");
      })
      .catch((error) => {
        console.error("Erro ao exportar:", error);
      });
  };

  const fetchBirthDate = async (cpf: string) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `https://acelereai.gfttech.com.br/api/v1/marketplace/benefits?cpf=${cpf}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data && response.data[0] && response.data[0].beneficio) {
        setBirthDate(response.data[0].beneficio.dataNascimento);
      } else {
        setBirthDate("");
      }
    } catch (error) {
      console.error("Erro ao buscar data de nascimento:", error);
      setBirthDate("");
    }
  };

  const handleOpenTypingModal = async (bankKey: string, bankData: BankData, simulation: Simulation) => {
    setSelectedBankTyping(bankKey);
    setNumberContract(bankData.id);
    setSelectedBankData(bankData);
    setSelectedSimulationForDigitation(simulation);
    await fetchBirthDate(simulation.cpf);
    setShowDigitationModal(true);
  };

  const handleCloseDigitationModal = () => {
    setShowDigitationModal(false);
    setSelectedBankTyping(null);
    setSelectedBankData(null);
    setSelectedSimulationForDigitation(null);
    setNumberContract(null);
    setBirthDate("");
  };

  const handleClosePortTypingModal = () => {
    setShowPortTypingModal(false);
    setSelectedConditionId(null);
    setSelectedSimulationForPort(null);
  };

  const renderBankData = (bankKey: string, bankData: BankData[], simulation: Simulation) => {
    if (!bankData) return null;

    return bankData.map((data: BankData, index) => {
      return (
        <tr key={`${bankKey}-${index}`}>
          <td>{renderBankName(bankKey)}</td>
          <td>{data.produto || ""}</td>
          <td>{data.parcelas}</td>
          <td>{formatCurrency(data.valorParcela)}</td>
          <td>
            {formatCurrency(
              data.produto === "REFINANCIAMENTO" ? data.valorTroco : data.valorLiquido
            )}
          </td>
          <td>
            {formatCurrency(
              data.produto === "REFINANCIAMENTO" ? data.valorLiquido : data.valorLiquido
            )}
          </td>
          <td>{data.taxaCliente?.toFixed(2)}%</td>
         
        </tr>
      );
    });
  };

  const renderCartaoData = (bankKey: string, bankData: BankData[], simulation: Simulation) => {
    if (!bankData) return null;

    return bankData.map((data: BankData, index) => {
      return (
        <tr key={`${bankKey}-${index}`}>
          <td>{renderBankName(bankKey)}</td>
          <td>{data.produto}</td>
          <td>{formatCurrency(data.valorSaque)}</td>
          <td>{formatCurrency(data.valorCompra)}</td>
          <td>{formatCurrency(data.valorLimite)}</td>
         
        </tr>
      );
    });
  };

  const countSuccessfulBanks = (simulations: Simulation[]): number => {
    let successfulCount = 0;

    simulations.forEach((simulation: Simulation) => {
      if (simulation.condicoes && typeof simulation.condicoes === "object") {
        Object.values(simulation.condicoes).forEach((bankData: any) => {
          if (Array.isArray(bankData)) {
            successfulCount += bankData.filter(
              (data: BankData) => data.status === "success"
            ).length;
          }
        });
      }
    });

    return successfulCount;
  };

  const successfulBanksCount = countSuccessfulBanks(simulations);

  const groupSimulationsByCpf = () => {
    const grouped: {
      [cpf: string]: {
        simulation: Simulation;
        emprestimoData: {
          [bank: string]: BankData[];
        };
        cartaoData: {
          [bank: string]: BankData[];
        };
      };
    } = {};

    simulations.forEach((simulation) => {
      if (!grouped[simulation.cpf]) {
        grouped[simulation.cpf] = {
          simulation: simulation,
          emprestimoData: {},
          cartaoData: {},
        };
      }

      if (simulation.condicoes && typeof simulation.condicoes === "object") {
        Object.entries(simulation.condicoes).forEach(([bankKey, bankArray]) => {
          if (Array.isArray(bankArray)) {
            const successfulEmprestimoData = bankArray.filter(
              (data: BankData) =>
                data.status === "success" &&
                data.produto !== "Cartão RMC" &&
                data.produto !== "Cartão RCC"
            );
            const successfulCartaoData = bankArray.filter(
              (data: BankData) =>
                data.status === "success" &&
                (data.produto === "Cartão RMC" || data.produto === "Cartão RCC")
            );

            if (successfulEmprestimoData.length > 0) {
              if (!grouped[simulation.cpf].emprestimoData[bankKey]) {
                grouped[simulation.cpf].emprestimoData[bankKey] = [];
              }
              grouped[simulation.cpf].emprestimoData[bankKey].push(...successfulEmprestimoData);
            }

            if (successfulCartaoData.length > 0) {
              if (!grouped[simulation.cpf].cartaoData[bankKey]) {
                grouped[simulation.cpf].cartaoData[bankKey] = [];
              }
              grouped[simulation.cpf].cartaoData[bankKey].push(...successfulCartaoData);
            }
          }
        });
      }
    });

    return grouped;
  };

  const groupedSimulations = groupSimulationsByCpf();

  const renderAccordionContent = (
    data: {
      [bank: string]: BankData[];
    },
    isCartao: boolean = false,
    simulation: Simulation
  ) => {
    if (Object.keys(data).length === 0) {
      return (
        <Alert variant="warning">
          Nenhuma simulação bem-sucedida encontrada para este CPF.
        </Alert>
      );
    }

    return (
      <>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Banco</th>
              <th>Produto</th>
              {isCartao ? (
                <>
                  <th>Vlr.Saque</th>
                  <th>Vlr.Compra</th>
                  <th>Vlr.Limite</th>
                </>
              ) : (
                <>
                  <th>Parcelas</th>
                  <th>Vlr.Parcela</th>
                  <th>Vlr.Líquido</th>
                  <th>Vlr.Bruto</th>
                  <th>Taxa</th>
                </>
              )}
              <th>Digitar</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(data).map(([bankKey, bankDataArray]) =>
              isCartao
                ? renderCartaoData(bankKey, bankDataArray, simulation)
                : renderBankData(bankKey, bankDataArray, simulation)
            )}
          </tbody>
        </Table>
      </>
    );
  };

  const MargemInfo = ({ simulation }: { simulation: Simulation }) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Badge bg="secondary" style={{ marginRight: "5px" }}>
          Margem Disponível: {formatCurrency(simulation.margem?.margemDisponivel)}
        </Badge>
        <Badge bg="secondary" style={{ marginRight: "5px" }}>
          Margem RMC: {formatCurrency(simulation.margem?.margemDisponivelCartao)}
        </Badge>
        <Badge bg="secondary">
          Margem RCC: {formatCurrency(simulation.margem?.margemDisponivelRCC)}
        </Badge>
      </div>
    );
  };

  return (
    <Modal show={show} onHide={onHide} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>
          <PageSubTitle size="h3">Detalhes do Lote {}</PageSubTitle>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.modalBody}>
        {successfulBanksCount > 0 && (
          <Alert className={`${styles.alertTitleOportunity} mb-4`} variant="light">
            Você tem {successfulBanksCount} Oportunidades de negócios! 🚀
          </Alert>
        )}

        {error && <Alert variant="secondary">{error}</Alert>}
        {loading ? (
          <SpinnerDefault />
        ) : (
          <>
            <Tabs defaultActiveKey="emprestimo" className="mb-3">
              <Tab eventKey="emprestimo" title="Empréstimo">
                {Object.entries(groupedSimulations).map(([cpf, groupedData]) => (
                  <Accordion key={cpf}>
                    <Accordion.Item eventKey={cpf}>
                      <Accordion.Header>
                        <div className="d-flex align-items-center">
                          CPF: {formatCPF(cpf)}
                          <div className="me-2" style={{ marginLeft: 20 }}>
                            <MargemInfo simulation={groupedData.simulation} />
                          </div>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        {renderAccordionContent(
                          groupedData.emprestimoData,
                          false,
                          groupedData.simulation
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                ))}
              </Tab>
              <Tab eventKey="cartao" title="Cartão">
                {Object.entries(groupedSimulations).map(([cpf, groupedData]) => (
                  <Accordion key={cpf}>
                    <Accordion.Item eventKey={cpf}>
                      <Accordion.Header>
                        <div className="d-flex align-items-center">
                          CPF: {formatCPF(cpf)}
                          <div className="me-2" style={{ marginLeft: 20 }}>
                            <MargemInfo simulation={groupedData.simulation} />
                          </div>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        {renderAccordionContent(
                          groupedData.cartaoData,
                          true,
                          groupedData.simulation
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                ))}
              </Tab>
            </Tabs>

            <Pagination
              currentPage={currentPage}
              totalItems={totalItems}
              itemsPerPage={itemsPerPage}
              onPageChange={handlePageChange}
            />
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <ButtonPrimary content="Exportar" onClick={() => handleExport(batchId)} />
      </Modal.Footer>
     
    </Modal>
  );
};

export default ViewDataBatchSimulation;