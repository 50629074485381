import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { faHome, faBars, faChevronDown, faChevronUp, faBell, faUser, faUsers, faUserTie, faSearch, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './NavBar.module.scss';
import { Dropdown, Modal } from 'react-bootstrap';
import sideBarLogo from '../../assets/images/sidebar-logo.png';
import ButtonPrimary from '../Buttons/ButtonPrimary';
import ButtonGray from '../Buttons/ButtonGray';
import AvailableBalance from '../AvailableBalance';

interface Notification {
    id: number;
    message: string;
    isRead: boolean;
}

interface NavBarProps {
    onToggleMenu?: (isMenuOpen: boolean) => void;
}

const NavBar: React.FC<NavBarProps> = ({ onToggleMenu }) => {
    const [menuOpen, setMenuOpen] = useState(localStorage.getItem('isMenuOpen') === 'true');
    const [subMenuUnitariaOpen, setSubMenuUnitariaOpen] = useState(false);
    const [subMenuLoteOpen, setSubMenuLoteOpen] = useState(false);
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const [unreadCount, setUnreadCount] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        localStorage.setItem('isMenuOpen', menuOpen.toString());
        if (onToggleMenu) {
            onToggleMenu(menuOpen);
        }
    }, [menuOpen, onToggleMenu]);

    useEffect(() => {
        // Simula a busca de notificações
        const fetchNotifications = async () => {
            const fetchedNotifications: Notification[] = [
                // { id: 1, message: 'Nova mensagem de suporte', isRead: false },
                // { id: 2, message: 'Atualização disponível', isRead: false },
                // { id: 3, message: 'Seu perfil foi atualizado', isRead: true }
            ];
            setNotifications(fetchedNotifications);
            setUnreadCount(fetchedNotifications.filter((n) => !n.isRead).length);
        };

        fetchNotifications();
    }, []);

    const toggleMenu = () => setMenuOpen(!menuOpen);
    const toggleSubMenuUnitaria = () => setSubMenuUnitariaOpen(!subMenuUnitariaOpen);
    const toggleSubMenuLote = () => setSubMenuLoteOpen(!subMenuLoteOpen);

    const handleAccount = () => navigate('/MinhaConta');
    const handleLogout = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    const Logout = async () => {
        const token = localStorage.getItem('token');

        if (!token) {
            console.error('Nenhum token encontrado!');
            return;
        }

        try {
            const res = await fetch('https://webservices.gfttech.com.br/api/v1/sair', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!res.ok) {
                throw new Error('Logout falhou!');
            }

            localStorage.removeItem('token');
            localStorage.clear();
            window.location.href = '/login';
        } catch (error) {
            console.error('Erro no logout:', error);
        }
    };

    const markNotificationAsRead = (id: number) => {
        const updatedNotifications = notifications.map((notification) => {
            if (notification.id === id) {
                return { ...notification, isRead: true };
            }
            return notification;
        });
        setNotifications(updatedNotifications);
        setUnreadCount(updatedNotifications.filter((n) => !n.isRead).length);
    };

    return (
        <div className={styles.mainContainer}>
            <div className={`${styles.sideBar} ${menuOpen ? styles.open : styles.closed}`}>
                <div className={styles.header}>
                    <img src={sideBarLogo} alt="Logo" />
                    <FontAwesomeIcon
                        icon={faBars}
                        onClick={toggleMenu}
                        className={styles.bars}
                    />
                </div>
                <div className={styles.content}>
                    <NavLink to="/PainelGeral" className={styles.navItem}>
                        <FontAwesomeIcon icon={faHome} className={styles.navIcon} />
                        <span className={styles.textItem}>Home</span>
                    </NavLink>

                    <div className={styles.navItem} onClick={toggleSubMenuUnitaria}>
                        <FontAwesomeIcon icon={faUser} className={styles.navIcon} />
                        <span className={styles.textItem}>Consulta Unitária</span>
                        <FontAwesomeIcon
                            icon={subMenuUnitariaOpen ? faChevronUp : faChevronDown}
                            className={styles.chevronIcon}
                        />
                    </div>
                    {subMenuUnitariaOpen && (
                        <div className={styles.subMenu}>
                            <NavLink to="/ConsultaUnitaria/Inss/SelecaoBancos" className={styles.subMenuItem}>
                                INSS
                            </NavLink>
                            <NavLink to="/ConsultaUnitaria/Convenios" className={styles.subMenuItem}>
                                Convênios
                            </NavLink>
                        </div>
                    )}

                    <div className={styles.navItem} onClick={toggleSubMenuLote}>
                        <FontAwesomeIcon icon={faUsers} className={styles.navIcon} />
                        <span className={styles.textItem}>Consulta Lote</span>
                        <FontAwesomeIcon
                            icon={subMenuLoteOpen ? faChevronUp : faChevronDown}
                            className={styles.chevronIcon}
                        />
                    </div>
                    {subMenuLoteOpen && (
                        <div className={styles.subMenu}>
                            <NavLink to="/ConsultaLote/INSS/SelecaoBancos" className={styles.subMenuItem}>
                                INSS
                            </NavLink>
                        </div>
                    )}
                    <NavLink to="/ListagemSimulacoes" className={styles.navItem}>
                        <FontAwesomeIcon icon={faPaperPlane} className={styles.navIcon} />
                        <span className={styles.textItem}>Simulações</span>
                    </NavLink>
                </div>
            </div>

            {!menuOpen && (
                <div className={styles.toggleMenu}>
                    <FontAwesomeIcon icon={faBars} onClick={toggleMenu} className={styles.barsIconSemiTransparent} />
                </div>
            )}

            <div className={`${styles.topBar} ${menuOpen ? styles.menuOpen : ''}`}>
                <div className={styles.topBarIconsContainer}>
                    <Dropdown>
                        <Dropdown.Toggle as="div" className={styles.dropdownIcon}>
                            <FontAwesomeIcon icon={faBell} className={styles.topBarIcons} />
                            {unreadCount > 0 && <span className={styles.notificationBadge}>{unreadCount}</span>}
                        </Dropdown.Toggle>

                        <Dropdown.Menu className={styles.dropdown}>
                            {notifications.length > 0 ? (
                                notifications.map((notification) => (
                                    <Dropdown.Item
                                        key={notification.id}
                                        className={styles.dropdownItem}
                                        onClick={() => markNotificationAsRead(notification.id)}
                                    >
                                        {notification.message}
                                        {!notification.isRead && <span className={styles.unreadIndicator} />}
                                    </Dropdown.Item>
                                ))
                            ) : (
                                <Dropdown.Item className={styles.dropdownItem}>
                                    Nenhuma notificação
                                </Dropdown.Item>
                            )}
                            {/*<div className="d-flex justify-content-center mt-2 fs-6">
                                <a href="/Notificacoes" className={styles.link}> Ver todas </a>
                            </div>*/}
                        </Dropdown.Menu>
                    </Dropdown>

                    <Dropdown>
                        <Dropdown.Toggle as="div" className={styles.dropdownIcon}>
                            <FontAwesomeIcon icon={faUserTie} className={styles.topBarIcons} />
                        </Dropdown.Toggle>

                        <Dropdown.Menu className={styles.dropdown}>
                            <Dropdown.Item 
                                onClick={handleAccount}
                                className={styles.dropdownItem}
                            >
                                Minha Conta
                            </Dropdown.Item>
                            <Dropdown.Item 
                                onClick={handleLogout}
                                className={styles.dropdownItem}
                            >
                                Sair
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>

                    <Modal show={showModal} onHide={handleCloseModal}>
                        <Modal.Header closeButton>
                            <Modal.Title className={styles.logoutTitle}>Confirmar Saída?</Modal.Title>
                        </Modal.Header>
                        <Modal.Footer>
                            <ButtonGray content="Cancelar" onClick={handleCloseModal} />
                            <ButtonPrimary content="Sair" onClick={Logout} />
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
        </div>
    );
};

export default NavBar;
